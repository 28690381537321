import React, { ReactElement, useEffect } from 'react';
import { Button } from 'govuk-react-jsx';
import QueryString from 'query-string';
import type App from '../App';

export default function Start(
  { setInitialValues }: { setInitialValues: typeof App.prototype.setState },
): ReactElement {
  useEffect(() => {
    const { dataSourceID, acquisitionID } = QueryString.parse(window.location.search);
    if (Array.isArray(dataSourceID) || Array.isArray(acquisitionID)) throw new Error('Query string contains array');
    setInitialValues({ initialDataSourceID: dataSourceID ?? '', initialAcquisitonID: acquisitionID ?? '' });
  }, [setInitialValues]);
  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <h1 className="govuk-heading-l">Return data</h1>
        <p className="govuk-body">Use this service to return data for a commission.</p>
        <p className="govuk-body">Before you start, check you have:</p>
        <ul className="govuk-list govuk-list--bullet">
          <li>A Data Source ID for the metrics your data contains</li>
          <li>A Acquisition ID for the return you are making</li>
          <li>Your data in a CSV file</li>
        </ul>
        <Button isStartButton to="/upload/1">Start Now</Button>
      </div>
    </div>
  );
}
