import React, { SyntheticEvent } from 'react';
import { connect } from 'formik';
import { Input } from 'govuk-react-jsx';

export default connect<{}, { dataSourceID: string }>(({ formik }) => (
  <>
    <Input
      id="dataSourceID"
      name="dataSourceID"
      type="text"
      label={{ children: ['Data Source ID'] }}
      hint={{ children: ['If you don’t know your data source ID, you won’t be able to upload your data using this form. The link sent to you will prefill this information on your behalf.'] }}
      value={formik.values.dataSourceID}
      onChange={(e: SyntheticEvent<HTMLInputElement>) => {
        formik.setErrors({});
        formik.handleChange(e);
      }}
      onBlur={formik.handleBlur}
      errorMessage={formik.touched.dataSourceID
        && formik.errors.dataSourceID && { children: [formik.errors.dataSourceID] }}
    />
  </>
));
