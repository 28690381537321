import FileType from 'file-type/browser';
import { decode as utf8Decode, encode as utf8Encode } from 'utf8'

import { FormikState } from 'formik';
import { UploadMeta, Values } from '../FormState';


function readCSV(contents: string): UploadMeta {
  let maxFieldsCount = 0;
  const rows = contents.split(/\r\n|\n/u);
  let rowsCount = 0;
  rows.forEach((line) => {
    if (line === '') return;
    rowsCount += 1;
    const fieldsCount = line.split(',').length;
    if (fieldsCount > maxFieldsCount) maxFieldsCount = fieldsCount;
  });
  return {
    fields: maxFieldsCount,
    pages: 1,
    records: rowsCount,
  };
}


async function getFileContentType(file: Response) {
  const d = await FileType.fromBlob(await file.blob())
  // FileType will only establish the type of non-text files.
  // Else, it returns undefined. Making it highly likely it is text (csv) based.
  if (d) {
    return d.ext // return ext, e.g. png
  }
  return 'text'
}

async function checkUploadedCSVFile(file: File) {
  const fileLoaded = await new Response(file)
  const fileContentType = await getFileContentType(fileLoaded)
  if (fileContentType !== 'text') {
    throw new Error(`Bad file content type: ${fileContentType}`);
  } else { return fileContentType }
}

/**
 * Checks upload meta contains at least 1 record
 * @param uploadMeta
 * @returns {UploadMeta} if it contains at least 1 record
 * @throws {Error} if uploadMeta doesn't contain at least 1 record
 */
function checkUploadMeta(uploadMeta: UploadMeta): UploadMeta {
  if (!uploadMeta.records) throw new Error('File does not contain any data');
  return uploadMeta;
}

export default async function readFile(file: File): Promise<UploadMeta> {
  switch (file.type) {
    case 'text/csv':
    case 'text/plain':
    case 'application/vnd.ms-excel': // some browsers on windows will see csv files as this type
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { // unsure if required for csv
      await checkUploadedCSVFile(file)
      const fileRaw = await new Response(file).text() // returns unicode text represention of upload
      return checkUploadMeta(readCSV(utf8Decode(utf8Encode(fileRaw))))
    }
    default:
      throw new Error(`Unknown file type ${file.type}`);
  }
}

export const isParsing = (formik: FormikState<Values>): boolean => (
  !!formik.values.data && formik.values.meta.records === 0 && !formik.errors.data
);
