import React, {
  useCallback, SyntheticEvent, useEffect, useState,
} from 'react';
import { Button, FileUpload, ErrorSummary } from 'govuk-react-jsx';
import { connect } from 'formik';
import { Values, initialValues } from '../../FormState';
import readFile, { isParsing } from '../../processing/parse';
import Loading from '../../components/Loading';
import { acquisitionIdCheck } from '../../api/commissions';

const errorElement = (msg: string) => (
  <ErrorSummary
    titleChildren="There is a problem"
    errorList={[{
      children: (
        <>
          <p className="govuk-body">{msg}</p>
          <p className="govuk-body">Please Go Back And Change Acquisition ID</p>
        </>
      ),
    }]}
  />
);

const acquisitionRefresher = (
  dataSourceID: string,
  acquisitionID: string,
  setAcquisitions: React.Dispatch<React.SetStateAction<Boolean | Error | undefined>>,
) => async (abortSignal?: AbortSignal) => {
  try {
    setAcquisitions(await acquisitionIdCheck(dataSourceID, acquisitionID, abortSignal));
  } catch (e) {
    setAcquisitions(e);
  }
};

export default connect<{}, Values>(({
  formik,
}) => {
  const [acquisitions, setAcquisitions] = useState<Boolean | Error>();
  const refresh = useCallback(
    acquisitionRefresher(
      formik.values.dataSourceID,
      formik.values.acquisitionID,
      setAcquisitions,
    ),
    [setAcquisitions],
  );

  useEffect(() => {
    refresh();
  }, []);

  if (
    formik.values.dataSourceID === ''
    || formik.values.dataSourceID === initialValues.dataSourceID
    || formik.values.acquisitionID === initialValues.acquisitionID
  ) return errorElement('Please enter information in all fields provided');

  if (!acquisitions) {
    return <p className="govuk-caption-m">Loading…</p>;
  }
  if (acquisitions instanceof Error) {
    return errorElement(acquisitions.message);
  }

  if (isParsing(formik)) return <Loading title="Your file is uploading" />;
  return (formik.values.data && !formik.errors.data && (
    <>
      <p className="govuk-heading-s">File details</p>
      <p className="govuk-body">{formik.values.data.name}</p>
      <p className="govuk-body-s">{`${formik.values.data.size} bytes`}</p>
      <Button
        className="govuk-button--secondary"
        onClick={() => formik.setValues({
          ...formik.values,
          data: undefined,
          meta: initialValues.meta,
        })}
      >
        Change file
      </Button>
      <br />
    </>
  ))
    || (
      <FileUpload
        id="file-upload"
        name="file-upload"
        label={{ children: ['Upload a file'] }}
        hint={{ children: ['Supported file types: CSV (.csv)'] }}
        errorMessage={formik.errors.data && { children: [formik.errors.data] }}
        onChange={(event: SyntheticEvent<HTMLInputElement>) => {
          if (!event.currentTarget.files) {
            formik.setFieldValue('data', undefined);
            formik.setFieldError('data', 'A file is required to use file upload.');
            return;
          }
          const currentFile = event.currentTarget.files?.[0];
          formik.setFieldValue('data', currentFile);
          readFile(currentFile)
            .then((meta) => {
              formik.setErrors({});
              formik.setFieldValue('meta', meta);
            })
            .catch((e: Error) => {
              formik.setFieldError('data', e.message);
            });
        }}
        accept=".csv"
      />
    );
});
