import React from 'react';
import { ErrorSummary } from 'govuk-react-jsx';
import SignOut from '../components/SignOut';

export default function noaccess() {
  return (
    <>
      <ErrorSummary
        errorList={[
          {
            children: [
              'Your account doesn\'t have sufficient privileges to access this service, please contact an Administrator.',
            ],
          },
        ]}
        titleChildren="There is a problem"
      />
      <SignOut />
    </>
  );
}
