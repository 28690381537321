import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { Button } from 'govuk-react-jsx';

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(`Error signing out: ${error.message}`);
  }
}

export default () => (
  <Button className="govuk-button--secondary" name="Sign Out" onClick={signOut}>
    Sign Out
  </Button>
);
