import React from 'react';
import { css } from '@emotion/core';
import FadeLoader from 'react-spinners/FadeLoader';

const override = css`
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export default function loading({ title }: { title: string }) {
  return (
    <div className="loading-root">
      <FadeLoader
        css={override}
        color="#505a5f"
      />
      <h3 className="govuk-heading-m">{title}</h3>
    </div>
  );
}
