import { Auth } from '@aws-amplify/auth';
import { encode as utf8Encode } from 'utf8'

export const validCommissionID = /^(COM_)\d+$/;

/**
 * Gets commission info for a commission ID from catalogue API
 * @param commissionID Commission ID
 * @param abortSignal
 * @returns commission info
 */
async function apiInteract(
  path: string,
  abortSignal?: AbortSignal,
  postData?: string,
): Promise<Response> {
  const apiPath = process.env.REACT_APP_API_BASE_URL + path;
  const auth = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  const response = postData ? await fetch(apiPath, {
    headers: {
      Authorization: auth,
    },
    signal: abortSignal,
    method: 'POST',
    body: postData,
  }) : await fetch(apiPath, {
    headers: {
      Authorization: auth,
    },
    signal: abortSignal,
  });

  return response;
}

export async function dataSourceCheck(
  dataSourceID: string,
  abortSignal?: AbortSignal,
): Promise<Boolean> {
  /*
  check that the data source requested is present and the user is authorised
  to access it, if not throw an error with a description of the problem
  */
  if (!validCommissionID.test(dataSourceID)) {
    throw new Error(`Data Source ID ‘${dataSourceID}’ was not provided in the correct format (COM_123).`);
  }

  const response = await apiInteract(`v1/data_upload/${dataSourceID}`, abortSignal);
  if (!response.ok) throw new Error(`${response.status}: ${await response.text()}`);

  return true;
}

export async function acquisitionIdCheck(
  dataSourceId: string,
  acquisitionId: string,
  abortSignal?: AbortSignal,
): Promise<Boolean> {
  /*
  check that the data source requested is present and the user is authorised
  to access it, if not throw an error with a description of the problem
  */
  if (!validCommissionID.test(dataSourceId)) {
    throw new Error(`Commission ID ‘${dataSourceId}’ was not provided in the correct format (COM_123).`);
  }

  const response = await apiInteract(`v1/data_upload/${dataSourceId}/acquisitions/${acquisitionId}`, abortSignal);
  if (!response.ok) throw new Error(`${response.status}: ${await response.text()}`);

  return true;
}

/**
 * Submits a csv file to the api which in turn stores the file
 * commission return
 * @param dataSourceID Data Source ID
 * @param acquisitionID Acquisition ID
 * @param file the file to be stored
 * @returns {string} which is the same Acquisition ID
 */
export async function fileSubmit(
  dataSourceID: string,
  acquisitionID: string,
  file: File,
): Promise<string> {
  const response = await apiInteract(`v1/data_upload/${dataSourceID}/acquisitions/${acquisitionID}`, undefined, utf8Encode(await file.text()));
  if (!response.ok) throw new Error(`${response.status}: ${await response.text()}`);
  return acquisitionID;
}
