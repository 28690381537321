import React, {
  useCallback, SyntheticEvent, useEffect, useState,
} from 'react';
import { connect } from 'formik';
import { Input, ErrorSummary } from 'govuk-react-jsx';
import { dataSourceCheck } from '../../api/commissions';

const errorElement = (msg: string) => (
  <ErrorSummary
    titleChildren="There is a problem"
    errorList={[{
      children: (
        <>
          <p className="govuk-body">{msg}</p>
          <p className="govuk-body">Please Go Back And Change Data Source ID</p>
        </>
      ),
    }]}
  />
);

const dataSourceRefresher = (
  dataSourceID: string,
  setDataSource: React.Dispatch<React.SetStateAction<Boolean | Error | undefined>>,
) => async (abortSignal?: AbortSignal) => {
  try {
    setDataSource(await dataSourceCheck(dataSourceID, abortSignal));
  } catch (e) {
    setDataSource(e);
  }
};
export default connect<{}, { dataSourceID: string, acquisitionID: string }>(({
  formik,
}) => {
  const [dataSource, setDataSource] = useState<Boolean | Error>();
  const refresh = useCallback(
    dataSourceRefresher(
      formik.values.dataSourceID,
      setDataSource,
    ),
    [setDataSource],
  );

  useEffect(() => {
    refresh();
  }, []);

  if (!dataSource) {
    return <p className="govuk-caption-m">Loading…</p>;
  }
  if (dataSource instanceof Error) {
    return errorElement(dataSource.message);
  }

  return (
    <>
      <Input
        id="acquisitionID"
        name="acquisitionID"
        type="text"
        label={{ children: ['Acquisition ID'] }}
        hint={{ children: ['If you don’t know your acquisition ID, you won’t be able to upload your data using this form. The link sent to you will prefill this information on your behalf.'] }}
        value={formik.values.acquisitionID}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          formik.setErrors({});
          formik.handleChange(e);
        }}
        onBlur={formik.handleBlur}
        errorMessage={formik.touched.acquisitionID
          && formik.errors.acquisitionID && { children: [formik.errors.acquisitionID] }}
      />
    </>
  );
});
