import React from 'react';
import {
  Route, BrowserRouter as Router, RouteChildrenProps, Switch,
} from 'react-router-dom';
import { Template, PhaseBanner } from 'govuk-react-jsx';
import { Auth } from '@aws-amplify/auth';
import { FormStep } from './FormState';
import Start from './pages/Start';
import NotFound from './pages/NotFound';
import FileUploadForm from './pages/FileUploadForm';
import Back from './components/Back';
import ErrorBoundary from './components/ErrorBoundary';
import AuthProvider from './AuthProvider';

const appName = 'Commission Data Upload';

type RouteDeclaration = {
  title: string,
  path: string,
  children: (props: RouteChildrenProps<any>) => React.ReactNode,
  showBackLink?: boolean,
};

const feedbackEmail = process.env.REACT_APP_FEEDBACK_EMAIL_UPLOADER ?? 'example@example.com';

const newServiceInfo = (
  <PhaseBanner tag={{ children: 'alpha' }}>
    This is a new service – your
    {' '}
    <a className="govuk-link" href={`mailto:${feedbackEmail}`}>feedback</a>
    {' '}
    will help us to improve it.
  </PhaseBanner>
)

export default class App extends React.Component<void, {
  initialDataSourceID: string,
  initialAcquisitonID: string,
}> {
  private readonly routes: (RouteDeclaration)[] = [
    {
      title: appName,
      path: '/',
      children: () => <Start setInitialValues={this.setState} />,
    },
    {
      title: 'Upload',
      path: `/upload/:step([${FormStep.commission}-${FormStep.confirmation}])`,
      children: ({ match }: RouteChildrenProps<{ step: string }>) => {
        const { initialDataSourceID, initialAcquisitonID } = this.state;
        return (
          <FileUploadForm
            step={Number(match?.params.step) as FormStep}
            initialDataSourceID={initialDataSourceID}
            initialAcquisitonID={initialAcquisitonID}
          />
        );
      },
      showBackLink: true,
    },
    {
      title: 'Page not found',
      path: '*',
      children: () => <NotFound />,
    },
  ];

  constructor() {
    super();
    this.state = {
      initialDataSourceID: '',
      initialAcquisitonID: '',
    };
    this.setState = this.setState.bind(this);
  }

  render() {
    return (
      <AuthProvider name={appName}>
        <Router>
          <Switch>
            {this.routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={!route.path.includes('*')}
              >
                {(props) => (
                  <Template
                    title={`${route.title}${route.path !== '/' ? ` - ${appName}` : ''}`}
                    skiplink={{ href: '#main-content', children: 'Skip to main content' }}
                    header={{
                      serviceName: appName,
                      serviceUrlHref: '/',
                      navigation: [{ children: ['Log out'], to: '/', onClick: () => Auth.signOut() }],
                      containerClassName: 'govuk-width-container nav-float-right',
                    }}
                    beforeContent={(
                      <>
                        {newServiceInfo}
                        {route.showBackLink && <Back />}
                      </>
                    )}
                  >
                    <ErrorBoundary>
                      {route.children(props)}
                    </ErrorBoundary>
                  </Template>
                )}
              </Route>
            ))}
          </Switch>
        </Router>
      </AuthProvider>
    );
  }
}
