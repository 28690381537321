import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Panel } from 'govuk-react-jsx';

const done = (reference: string, tryAgain: () => void) => (
  <>
    <Panel titleChildren="Upload complete">{`Your reference: ${reference}`}</Panel>
    {/* <p className="govuk-body">We have sent you a confirmation …</p>
    <h2 className="govuk-heading-m">What happens next</h2>
    <p className="govuk-body">We’ve sent your application to …</p>
    <p className="govuk-body">They will …</p> */}
    <p className="govuk-body">
      You can
      {' '}
      <Link to="/" onClick={tryAgain}>use the service again</Link>
      .
    </p>
  </>
);

const previouslyDone = (
  <>
    <h1 className="govuk-heading-l">Upload submitted</h1>
    <p className="govuk-body">The upload you are trying to access has been submitted.</p>
    <p className="govuk-body">To continue you can:</p>
    <ul className="govuk-list govuk-list--bullet">
      <li><Link to="/">use the service again</Link></li>
    </ul>
  </>
);

export default ({ reference }: { reference: string }) => {
  if (!reference) return previouslyDone;
  const history = useHistory();
  const formik = useFormikContext();
  return done(reference, () => {
    formik.resetForm();
    history.push('/');
  });
};
