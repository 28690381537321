import React, { useEffect, useState } from 'react';
import { Button } from 'govuk-react-jsx';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { FormStep, initialValues, submit } from '../FormState';
import InternalError from './InternalError';
import DataSourceRequest from './form/DataSourceRequest';
import AcquisitionRequest from './form/AcquisitionRequest';
import FileUpload from './form/FileUpload';
import CheckData from './form/CheckData';
import Submission from './form/Submission';
import Confirmation from './form/Confirmation';
import { isParsing } from '../processing/parse';

type Props = {
  step: FormStep,
  initialDataSourceID: string,
  initialAcquisitonID: string,
};

interface ErrorStepInterface { error: any, step: FormStep, href: string }

export default ({ step, initialDataSourceID, initialAcquisitonID }: Props) => {
  const [reference, setReference] = useState<string | Error>('');
  const [stepApplicableErrors, setStepApplicableErrors] = useState<ErrorStepInterface[]>([]);
  const history = useHistory();
  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          dataSourceID: initialDataSourceID || initialValues.dataSourceID,
          acquisitionID: initialAcquisitonID || initialValues.acquisitionID,
        }}
        validate={(values) => {
          switch (step) {
            case FormStep.commission:
              return {
                ...(values.dataSourceID === '' || values.dataSourceID === initialValues.dataSourceID)
                && { dataSourceID: 'Data Source ID is required.' },
              };
            case FormStep.commissionRequest:
              return {
                ...(values.acquisitionID === '')
                && { acquisitionID: 'Acquisition ID is required.' },
              };
            case FormStep.upload:
              return {
                ...(values.meta.records === 0)
                && { data: 'File upload is required.' },
              };
            default:
              return {};
          }
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          if (step < FormStep.summary) {
            history.push(`/upload/${step + 1}`);
            return;
          }
          history.push(`/upload/${FormStep.submission}`);
          try {
            setReference(await submit(values));
          } catch (e) {
            setReference(e);
          }
          history.push(`/upload/${FormStep.confirmation}`);
          formikHelpers.resetForm();
        }}
      >
        {(formik) => {
          const { values, errors, isSubmitting } = formik;
          useEffect(() => {
            function handleUnload(event: BeforeUnloadEvent): string | undefined {
              if (values.meta.records === 0) return undefined;
              event.preventDefault();
              // Chrome requires returnValue be set
              // eslint-disable-next-line no-param-reassign
              event.returnValue = '';
              return '';
            }
            window.addEventListener('beforeunload', handleUnload);
            return () => window.removeEventListener('beforeunload', handleUnload);
          }, [values.meta.records]);

          useEffect(() => {
            const errorSummaries: { error: any, step: FormStep, href: string }[] = [
              // List of possible errors, the step they can be resolved on
              // and an anchor link to the element which can affect the error
              { error: errors.dataSourceID, step: FormStep.commission, href: '#commission-id' },
              { error: errors.data, step: FormStep.upload, href: '#file-upload' },
            ];
            const errorsState2 = errorSummaries
              .filter(({ error, step: applicableStep }) => error && applicableStep === step);
            setStepApplicableErrors(errorsState2);
          }, [errors.dataSourceID, errors.data]);

          return (
            <Form>
              {step < FormStep.summary && <span className="govuk-caption-l">{`Question ${step} of ${FormStep.upload}`}</span>}
              {step < FormStep.confirmation && (
                <h1 className="govuk-heading-l">
                  {
                    [
                      'Enter your Data Source ID',
                      'Enter your Acquisition ID',
                      'Upload your data',
                      'Check your data',
                      'Your data is being uploaded',
                    ][step - 1]
                  }
                </h1>
              )}
              {
                [
                  <DataSourceRequest />,
                  <AcquisitionRequest />,
                  <FileUpload />,
                  <CheckData
                    summaryRows={[
                      { step: FormStep.commission, name: 'Data Source ID', value: values.dataSourceID },
                      { step: FormStep.commissionRequest, name: 'Acquisition ID', value: values.acquisitionID },
                      { step: FormStep.upload, name: 'File name', value: values.data?.name ?? 'unknown' },
                      { step: FormStep.upload, name: 'Number of pages', value: values.meta.pages.toString() },
                      { step: FormStep.upload, name: 'Number of fields', value: values.meta.fields.toString() },
                      { step: FormStep.upload, name: 'Number of records', value: values.meta.records.toString() },
                    ]}
                  />,
                  <Submission count={values.meta.records} />,
                  (() => (reference instanceof Error
                    && <InternalError errorMessage={reference.message} />)
                    || <Confirmation reference={reference as string} />)(),
                ][step - 1]
              }
              {step === FormStep.summary && <Button type="button" className="govuk-button--secondary" to={`/upload/${FormStep.upload}`}>Change data</Button>}
              {step <= FormStep.summary && <Button disabled={isParsing(formik) || isSubmitting || stepApplicableErrors.length > 0}>{step === FormStep.summary ? 'Submit' : 'Continue'}</Button>}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
