import React from 'react';
import { useHistory } from 'react-router-dom';
import { SummaryList } from 'govuk-react-jsx';
import { FormStep } from '../../FormState';

type SummaryRow = {
  step: FormStep,
  // Title of the step shown in the summary
  name: string,
  value: string
};

type SummaryListRow = {
  actions: { items: { children: string[], to: string, visuallyHiddenText: string }[] },
  key: { children: React.ReactNode[] },
  value: { children: React.ReactNode[] }
};

/**
 * Generate summary rows for the SummaryList component.
 * @param rows to generate summary from
 */
function generateSummary(rows: SummaryRow[]): SummaryListRow[] {
  return rows.map((row) => ({
    actions: {
      items: [
        {
          children: ['Change'],
          to: `/upload/${row.step}`,
          visuallyHiddenText: row.name,
        },
      ],
    },
    key: {
      children: [row.name],
    },
    value: {
      children: [row.value],
    },
  }));
}

export default ({ summaryRows }: { summaryRows: SummaryRow[] }) => {
  if (Number(summaryRows.find((value) => value.name === 'Number of records')?.value) < 1) useHistory().goBack();
  return <SummaryList rows={generateSummary(summaryRows)} />;
};
