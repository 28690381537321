import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn, AmplifyContainer } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { CognitoUser } from 'amazon-cognito-identity-js';
import amplifyConfig from './amplifyConfig';
import NoAccess from './pages/NoAccess';

Amplify.configure(amplifyConfig);

const ALLOWED_COGNITO_GROUPS = ['upload_users'];

export default function AuthProvider(
  { children, name }: InferProps<typeof AuthProvider.propTypes>,
) {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<CognitoUser>();

  React.useEffect(() => onAuthUIStateChange((nextAuthState, authData) => {
    setAuthState(nextAuthState);
    if (authData) setUser(authData as CognitoUser);
  }), []);

  if (authState !== AuthState.SignedIn) {
    return (
      <AmplifyContainer>
        <AmplifyAuthenticator>
          <AmplifySignIn
            headerText={name}
            slot="sign-in"
            hideSignUp
          />
        </AmplifyAuthenticator>
      </AmplifyContainer>
    );
  }

  const userGroups: string[] = user?.getSignInUserSession()?.getIdToken().decodePayload()['cognito:groups'] ?? [];
  const hasAccess = user && ALLOWED_COGNITO_GROUPS.some((group) => userGroups.includes(group));
  return hasAccess ? <>{children}</> : <NoAccess />;
}

AuthProvider.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};
