import React from 'react';
import { useHistory } from 'react-router-dom';

export default ({ count }: { count: number }) => {
  if (count === 0) useHistory().goBack();
  return (
    <>
      <h1 className="govuk-heading-m">{`Submitting ${count} value${count > 1 ? 's' : ''}…`}</h1>
      <p className="govuk-body">Do not navigate away from this page. Your data is being uploaded.</p>
    </>
  );
};
