import { fileSubmit } from './api/commissions';

export enum FormStep {
  commission = 1,
  commissionRequest,
  upload,
  summary,
  submission,
  confirmation,
}

export type Values = {
  dataSourceID: string,
  acquisitionID: string,
  data?: File,
  meta: UploadMeta
};
export const initialValues: Values = {
  dataSourceID: 'COM_',
  acquisitionID: '',
  meta: { pages: 0, fields: 0, records: 0 },
};

export async function submit(values: Values): Promise<string> {
  const reference = await fileSubmit(values.dataSourceID, values.acquisitionID, values.data!);
  return reference;
}

export type UploadMeta = {
  pages: number,
  fields: number,
  records: number,
};
